import Vue from 'vue';

import VueRouter from 'vue-router';
import Login from '../components/Login';
import Tutorials from '../components/Tutorials';
import Course from '../components/Course';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/tutorials',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/tutorials',
    name: 'Tutorials',
    component: Tutorials,
  },
  {
    path: '/tutorials/:course',
    name: 'Course',
    component: Course,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
