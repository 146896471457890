import Vue from 'vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from './App';
import router from './router';
import { BSidebar } from 'bootstrap-vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import { BListGroup } from 'bootstrap-vue'

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.component('b-sidebar', BSidebar);
Vue.component('b-list-group', BListGroup);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
