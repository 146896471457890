<template>
  <div class='container' >
    <v-alert
      :value="this.alert"
      :color="this.type"
      dark
      border="top"
      icon="mdi-home"
      transition="slide-y-transition"
      v-if="type"
      >
      Invalid Credentials!
    </v-alert>
    <form @submit.prevent="login" id='login-template'>
      <h3>Sign In</h3>

      <div class='form-group'>
        <label>Username</label>
        <input type='username' ref='username' class='form-control form-control-lg' />
      </div>

      <div class='form-group'>
        <label>Password</label>
        <input type='password' ref='password' class='form-control form-control-lg' />
      </div>

      <div class='form-group'>
      <br>
      <button type='submit' class='btn btn-dark btn-lg btn-block'>
        Sign In</button>
      </div>

    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Login',
  data() {
    return {
      input: {
        username: '',
        password: '',
        alert: false,
        type: null,
      },
    };
  },
  methods: {
    login() {
      this.input.username = this.$refs.username.value;
      this.input.password = this.$refs.password.value;
      if (this.input.username !== '' && this.input.password !== '') {
        const path = 'http://localhost:5000/login';
        axios.post(path, {
          username: this.input.username,
          password: this.input.password,
        })
          .then((res) => {
            if ('username' in res.data) {
              localStorage.username = res.data.username;
              localStorage.role = res.data.role;
              if (res.data.role === 'ServiceWriter') {
                this.$router.push('/service-vehicle');
              } else {
                this.$router.push('/vehicles');
              }
              this.$router.go(this.$router.currentRoute);
            } else {
              this.alert = true;
              this.type = 'warning';
            }
          })
          .catch((error) => {
            localStorage.username = 'dummy';
            this.$router.go(this.$router.currentRoute);
            this.type = 'warning';
            console.error(error);
          });
        if (this.input.username === this.$parent.mockAccount.username
          && this.input.password === this.$parent.mockAccount.password) {
          this.$emit('authenticated', true);
          this.$router.replace({ name: 'secure' });
        }
      }
    },
  },
};
</script>

<style scoped>
  #login-template {
    width: 500px;
    border: 1px solid #CCCCCC;
    background-color: #FFFFFF;
    margin: auto;
    padding: 20px;
  }
  .container {
    padding: 50px;
  }
</style>
