<template>
  <div id="app">
    <Header/>
    <div id="component">
      <router-view/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import 'sass/app.scss';
</style>

<script type="text/javascript">

import Header from './components/Header.vue';

export default {
  components: {
    Header,
  },
};

</script>
