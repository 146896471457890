<template>
  <div class="sidebar" ref="sidebar" v-bind:style="{width: expanded ? '300px' : '80px'}">
    <a class="h1 mb-2 toggle" @click.prevent="toggleSidebar">
      <b-icon icon="list" class="rounded"></b-icon>
    </a>
    <div v-if="expanded">
      <b-list-group class="lesson" v-for="(lesson,index) in course" :key="index">
        <a @click.prevent="updateContext(index)">
        <b-list-group-item :active="index == active">
          {{ lesson.title }}
        </b-list-group-item>
        </a>
      </b-list-group>
    </div>
    <div v-else>
      <b-list-group class="lesson" v-for="(lesson,index) in course" :key="index">
        <b-list-group-item :active="index == active">
          <b-icon v-if="!lesson.completed" icon="app-indicator" class="rounded"></b-icon>
          <b-icon v-else icon="app" class="rounded"></b-icon>
        </b-list-group-item>
      </b-list-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  props: ['course'],
  data() {
    return {
      expanded: true,
      active: 0,
    }
  },
  methods: {
    toggleSidebar() {
      this.expanded = !this.expanded;
      this.$parent.reSize(this.expanded);
    },
    updateContext(chId) {
      localStorage.setItem('currentChapter', chId);
      this.active = chId;
      this.$root.$emit('currentChapter', chId);
    },
  },
  created() {
    let currChapterId = localStorage.getItem('currentChapter');
    if (currChapterId) {
      this.active = currChapterId;
    } else {
      localStorage.setItem('currentChapter', 0);
    }
  },
}
</script>

<style scoped>
.sidebar {
  width: 300px;
  /* background: #343a40; */
  position: absolute;
  top: 61px;
  bottom: 0;
  box-shadow: 5px 5px 10px lightgrey;
}
.toggle {
  padding: 10px;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: #000;
  text-decoration: none;
}
.bi-list {
  box-shadow: 2px 2px 5px lightgrey;
}
.lesson {
  border-radius: 0%;
  padding-top: 20px;
}
</style>