<template>
  <div class="course">
    <Sidebar class="sidebar" :course="course"/>
    <Content v-bind:style="{left: expanded ? '305px' : '85px'}" class="content" :course="course"/>
  </div>
</template>

<script>
import Sidebar from './Sidebar';
import Content from './Content';
export default {
  name: 'LinuxTutorial',
  data() {
    return {
      expanded: true,
      course: [
        {
          title: 'Chapter 1',
          completed: true,
          parts: [
            {
              title: 'pwd',
              prompt: 'THIS IS A PROMPT',
              command: 'THIS IS THE COMMAND',
            },
          ]
        },
        {
          title: 'Chapter 2',
          completed: false,
          parts: [
            {
              title: 'pwd',
              prompt: 'THIS IS A PROMPT',
              command: 'THIS IS THE COMMAND',
            },
          ]
        },
        {
          title: 'Chapter 3',
          completed: false,
          parts: [
            {
              title: 'pwd',
              prompt: 'THIS IS A PROMPT',
              command: 'THIS IS THE COMMAND',
            },
          ]
        },
        {
          title: 'Chapter 4',
          completed: false,
          parts: [
            {
              title: 'pwd',
              prompt: 'THIS IS A PROMPT',
              command: 'THIS IS THE COMMAND',
            },
          ]
        },
      ]
    }
  },
  components: {
    Sidebar,
    Content,
  },
  methods: {
    reSize(isExpanded) {
      this.expanded = isExpanded;
    },
  },
}
</script>

<style scoped>
.course {
  padding: 0px !important;
  width: 100% !important;
  height: 100% !important;
}
.sidebar {
  width: 300px;
  /* background: #343a40; */
  position: absolute;
  top: 61px;
  bottom: 0;
  box-shadow: 5px 5px 10px lightgrey;
}
.content {
  left: 304px;
  /* background: #343a40; */
  position: absolute;
  top: 61px;
  bottom: 0;
  right: 0px;
  box-shadow: 5px 5px 10px lightgrey;
}
</style>