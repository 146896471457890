<template>
  <div class="content" ref="content">
    <p>{{ course[currChapterId] }}</p>
  </div>
</template>

<script>
export default {
  name: 'Content',
  props: ['course'],
  data() {
    return {
      currChapterId: 0,
    };
  },
  methods: {
      updateCurrChapter() {
        this.currChapterId = localStorage.getItem('currentChapter');
      },
  },
  created() {
    this.updateCurrChapter();
  },
  mounted() {
    this.$root.$on('currentChapter', chapterId => {
      this.currChapterId = chapterId;
    });
  },
}
</script>

<style scoped>
</style>