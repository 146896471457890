<template>
  <div class="tutorials">
    <h3>Launch a journey!</h3>
    <br>
    <b-card-group deck>
      <div class="tutorial" v-for="(tutorial,index) in tutorials" :key="index">
        <a :href="tutorial.link">
          <b-card 
              :title="tutorial.title"
              :img-src="tutorial.image"
              :img-alt="tutorial.title"
              img-top>
          </b-card>
        </a>
      </div>
    </b-card-group>
  </div>
</template>

<script>
export default {
  name: 'Tutorials',
  data() {
    return {
      tutorials: [
        {
          title: 'Linux Commands',
          image: 'static/img/linux.jpg',
          link: '/tutorials/linux',
        },
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-deck .tutorial {
  max-width: 23rem;
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
}
a {
  color: #000;
  text-decoration: none;
}
.row {
  display: flex;
  flex-wrap: wrap;
}

.card-deck {
  display: flex;
  flex-wrap: wrap;
}
.tutorials {
  padding: 50px;
}
</style>
