<template>
  <div class="header">
    <b-navbar type="dark" variant="dark">
      <b-navbar-nav>
        <b-nav-item @click.prevent="logout">{{this.authbutton}}</b-nav-item>
        <b-nav-item href="/tutorials">Tutorials</b-nav-item>
      </b-navbar-nav>
    </b-navbar>
  </div>
</template>

<script>

export default {
  name: 'Header',
  data() {
    return {
      authbutton: '',
      hasReportPerm: false,
      hasServPerm: false,
    };
  },
  methods: {
    logout() {
      if (this.authbutton === 'Logout') {
        localStorage.removeItem('username');
        localStorage.removeItem('role');
        this.authbutton = 'Login';
        this.$router.push('/vehicles');
      } else {
        this.$router.push('/login');
      }
      this.$router.go(this.$router.currentRoute);
    },
    getAuth() {
      if (localStorage.username) {
        this.authbutton = 'Logout';
        this.hasReportPerm = localStorage.role
          && (localStorage.role === 'Manager'
          || localStorage.role === 'Owner');
        this.hasServPerm = localStorage.role
          && (localStorage.role === 'ServiceWriter'
          || localStorage.role === 'Owner');
      } else {
        this.authbutton = 'Login';
      }
    },
  },
  mounted() {
    this.getAuth();
  },
};
</script>

<style scoped>
.navbar {
  height: 61px;
  padding: 0px;
}
</style>